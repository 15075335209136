body {
    font-family: sans-serif;
    z-index: 1;
    padding-bottom: 0.5rem;
  }
  
  input {
    border: 1px solid #999;
    padding: 0.5rem;
    width: 300px;
  }
  
  .no-suggestions {
    background-color: #dae0df;
    color: rgb(138, 10, 10);
    padding: 0.5rem;
    position:absolute;
    z-index: 1000;
  }
  
  .suggestions {
    border: 1px solid #999;
    background-color: #dae0df;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    width: calc(300px + 1rem);
    position:absolute;
    z-index: 1000;

 
  }
  
  .suggestions li {
    padding: 0.5rem;
  }
  
  .suggestion-active,
  .suggestions li:hover {
    background-color: #2e2e2e;
    color: #c4c4c4;
    cursor: pointer;
    font-weight: 700;
  }
  
  .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
  }